.single-post {
    .banner-block {
        h1 {
            .subtitle {
                margin-top: 0.5rem;
            }
        }
    }
}

.blog-post {
    .theme--custom, .custom-background-color {
        margin-left: -7.5rem;
        margin-right: -7.5rem;

        @include breakpoint(ML) {
            margin-left: -5rem;
            margin-right: -5rem;
        }

        @include breakpoint(S) {
            margin-left: -3.5rem;
            margin-right: -3.5rem;
        }

        @include breakpoint(XS) {
            margin-left: -2rem;
            margin-right: -2rem;
        }
    }
    p,
    li {
        max-width: unset;

        @include breakpoint(S) {
           // max-width: calc(100vw - 4rem);
        }
    }
    p {
        margin-bottom: 1.75rem;
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
       // margin-bottom: 2rem;
       max-width: unset;

        @include breakpoint(S) {
           // max-width: calc(100vw - 4rem);
        }
    }
    h2 {
        margin-top: 4.25rem;
        @include color(color, 'accent-punk-pink');
        text-shadow: 8px 8px 0 $color-body,
                     7px 7px 0 $color-body,
                     6px 6px 0 $color-body,
                     5px 5px 0 $color-body,
                     4px 4px 0 $color-body,
                     3px 3px 0 $color-body,
                     2px 2px 0 $color-body,
                     1px 1px 0 $color-body;
    }
    h3,
    h4 {
        margin-top: 1.75rem;
    }

    blockquote {
        margin-top: 6rem;
        @include color(background-color, 'accent-beach-blue');
        border-radius: $rounded-border-radius;

        p, cite {
            text-transform: uppercase;
            @include color(color, 'text-light');
        }

        p {
            font-family: $font-stack-headings;
            font-size: 1.75rem;
        }

        cite {
            font-family: $font-stack-headings;
            font-size: 1.3125rem;
        }
    }

    blockquote,
    .wp-block-embed,
    ul,
    ol,
    hr {
        margin-bottom: 1.75rem;
        max-width: unset;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(S) {
           // max-width: calc(100vw - 4rem);
        }
    }
    .wp-block-image {
        border-radius: $rounded-border-radius;

        figcaption {
            margin-top: 0;
            font-family: $font-stack-headings;
            font-size: 1.3125rem;
            @include color(color, 'body');
        }
    }
}

.post-actions {
    max-width: unset;
  //  margin: 6rem auto;

    @include breakpoint(XS) {
      //  margin: 4rem auto;
    }
}

.more-articles {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem;

    @include breakpoint(XS) {
        padding-top: 4.5rem;
        padding-bottom: 3rem;
    }
}

.posts-grid {
    margin-left: -1rem;
    margin-right: -1rem;

    &.rel-posts {
        padding-top: 3.5rem;
    }
    .post-item-img {
        display: block;

        img {
            height: 18rem;
            width: 100%;

            @supports (aspect-ratio: initial) {
                aspect-ratio: 4 / 3;
            }

            @include breakpoint(S) {

                @supports not (aspect-ratio: initial) {
                    height: 10rem;
                }
            }
            @include breakpoint(XS) {

                @supports not (aspect-ratio: initial) {
                    height: 15rem;
                }
            }
        }
    }
    .post-item {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 6rem;

        @include breakpoint(XS) {
            display: flex;
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
        &.third {
            width: calc(33.333% - 2rem);
            flex-grow: unset;

            @include breakpoint(S) {
                width: calc(50% - 2rem);
            }
            @include breakpoint(XS) {
                width: 100%;

                .post-item-img {
                    margin-right: 0rem;
                    margin-bottom: 0;


                    img {
                        width: 6rem;
                        height: 5rem;
                    }
                }
            }
        }
        h2,
        h3,
        h4 {
            a {
                @include color(color, 'body');

                &:hover {
                    @include color(color, 'accent-primary');
                }
            }
        }
        &.feat-post {
            width: 100%;
            flex-basis: unset;

            .post-item-img {
                width: 50%;
                position: relative;

                @include breakpoint(S) {
                    width: 100%;
                    height: 15rem;
                }
            }
            .feat-post-content {
                width: 50%;
                max-width: 30rem;
                padding-left: 2rem;
                padding-top: 3.5rem;
                padding-bottom: 3.5rem;

                @include breakpoint(S) {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}

.post-details {
    padding-top: 6rem;
    margin-bottom: 0;

    .heading {
        @include color(color, 'accent-punk-pink');
    }

    .button:not(:hover) {
        @include color(color, 'text-light');
    }

    .post-details-header-image > img {
        border: 1px solid;
        border-radius: $rounded-border-radius;
        max-height: 50vh;
    }

    @include breakpoint(XS) {
        padding-top: 4.5rem;
        margin-bottom: 8rem;
    }
    &.thin {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 9rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');

        @include breakpoint(XS) {
            margin-bottom: 4.5rem;

            .post-share {
                padding-top: 1.5rem;
            }
        }
    }
    &.has-excerpt {
        .post-info {
            min-width: 24rem;
            @include color(border-color, 'body-10');

            @include breakpoint(M) {
                min-width: 20rem;
            }
            @include breakpoint(XS) {
                min-width: auto;
                width: 100%;
                padding-right: 0;
                margin-right: 0;
                border-right: 0;
                border-bottom: 1px solid;
                @include color(border-color, 'body-10');
                padding-bottom: 3rem;
                margin-bottom: 3rem;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    .intro-content {
        p {
            @include size-L;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.event-details {
        @include breakpoint(XS) {
            padding-top: 2.25rem;
            margin-bottom: 4.5rem;

            .post-info {
                padding-bottom: 2rem;
            }
        }
    }
}

.post-info {
    strong {
        padding-right: 0.5rem;
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
        &:after {
            content: ",";
            padding-right: 0.25rem;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.post-share {
    .button {
        margin-left: 1rem;
    }
}

.posts-nav {
    border-top: 1px solid;
    @include color(border-color, 'body-10');
    padding: 3rem 0;

    @include breakpoint(XS) {
        padding: 0;
    }
    a {
        @include color(color, 'body');

        @include breakpoint(XS) {
            padding: 1.5rem 2rem;
            border-bottom: 1px solid;
            @include color(border-color, 'body-10');
        }
        &:hover {
            @include color(color, 'accent-primary');

            .icon {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
        &.prev {
            .icon {
                margin-right: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(-0.25rem);
                }
            }
        }
        &.next {
            .icon {
                margin-left: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(0.25rem);
                }
            }
        }
    }
}

.loadmore-wrapper {
    padding-top: 2rem;
}

.offer-share {
    .bordered {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');
    }
}

.addeventatc {
    cursor: pointer;
    position: relative;

    @include breakpoint(XS) {
        margin-bottom: 1.5rem;
    }
    &:hover,
    &.addeventatc-selected {
        .atc-button {
            @include color(color, 'accent-primary');
        }
    }
    .atc-button {
        @extend %transition;

        svg {
            margin-left: 0.75rem;
        }
        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"%3E%3Ccircle cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 17.5L12 6.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.5 12L6.5 12"/%3E%3C/svg%3E');
            margin-left: 0.75rem;

            @include breakpoint(XS) {
                width: 21px;
                height: 21px;
                background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"%3E%3Ccircle cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 17.5L12 6.5"/%3E%3Cpath fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.5 12L6.5 12"/%3E%3C/svg%3E');
            }
        }
    }
    .copyx {
        display: none;
    }
    .addeventatc_dropdown {
        position: absolute;
        @include color(background-color, 'background');
        border: 1px solid;
        @include color(border-color, 'body');
        margin-top: 0.75rem;
        min-width: 14rem;
        display: none;

        span {
            display: block;
            padding: 1.25rem 1rem;
            line-height: 1;
            border-top: 1px solid;
            @include color(border-color, 'body');
            @extend %transition;

            em {
                @extend %transition;
                font-size: 0.75rem;
            }
            &:first-child {
                border-top: 0;
            }
            &:hover {
                @include color(background-color, 'accent-primary');
                @include color(color, 'background');

                em {
                    @include color(color, 'background');
                }
            }
        }
    }
}

.post-feat-img {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@include breakpoint(M) {
    .blog-post-cards.cards-card-container {
        flex-wrap: wrap;
    }
}

//Styling for blog (overides)
.blog, .archive.category{
    .in-page-nav{
        width: calc(100% - 25rem);
        margin: 0 auto;
        overflow: hidden;
        display: flex!important;
        flex-direction: row;
        flex-wrap: unset;
        padding-left: 0;
        justify-content: center;
        @include breakpoint(S){
            width: calc(100% - 5rem);
            overflow:scroll;
            align-items: flex-start;
            place-content: flex-start;
        }
    }
    .container{
        padding-left: 5rem;
        padding-right: 5rem;
        @include breakpoint(S){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    .banner-block{
        padding-top: 11rem;
        height: auto;
        .banner-content-block{
            h1{
                display: flex;
                flex-direction: column;
                gap:1.5rem;
                span{
                    @include subtitle-1;
                    @include color(color, "body");
                    @include size-XL;
                    letter-spacing: 0.3em;
                }
            }
        }
    }
    .in-page-nav{
        @include overline;
    }
    .posts-grid{
        .feat-post{
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            margin-bottom: 6rem!important;
            .post-item-img{
                margin-bottom: 0!important;
                height: fit-content;
                @include breakpoint(S){
                    margin-bottom: 1.5rem!important;
                }
                img{
                    height: 100%;
                    object-fit: cover;
                }
            }
            .heading.h2{
                @include size-2XL;
            }
            .overline{
                font-family: $font-stack-subheadings;
                font-style: normal;
                @include size-M;
            }
            .subtitle-2{
                @include size-L;
                font-weight: bold;
                margin-top: 1rem;
            }
            a.button{
                width: fit-content;
            }
            .feat-post-content{
                max-width: unset;
                margin-left: 8.5rem;
                padding-left: 0;
                display: flex;
                flex-direction: column;
                align-self: center;
                @include breakpoint(ML){
                    margin-left: 5%;
                    padding-top: 0;
                }
                @include breakpoint(S){
                    margin-left: 0;

                }
            }
            @include breakpoint(S){
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .post-item{
            margin-bottom: 7.5rem!important;
            @include breakpoint(S){
                display: flex;
                flex-direction: column;
                margin-bottom:4.5rem!important;
            }
            .post-item-img{
                border: 1px solid #130839;
                border-radius: 24px;
                img{
                    position: relative;
                    border-radius: 24px;
                    aspect-ratio: 1/1;
                    height: auto;
                    @include breakpoint(S){
                        width: 100%;
                    }
                }
            }
            .small-excerpt{
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            .button{
                width: fit-content;
            }
            .post-item-content{
                h2{
                    display: flex;
                    flex-direction: column;
                    gap:0.25rem;
                    margin-bottom: 1.5rem;
                    span{
                        @include subtitle-2;
                        @include size-L;
                    }
                }
            }
        }
    }
}
.single.single-post{
    .post-details-header-image{
        width: 100%;
        img{
            width: 100%;
            height:32rem;
            max-height: unset;
        }
    }
    .blog-post-cards{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @include breakpoint(M){
            grid-template-columns: 1fr;
        }
        .card{
            width: 100%;
        }
    }
    .blog-post{
        > .container{
            padding-left: 0;
            padding-right: 0;
        }
    }
}