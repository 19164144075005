input[type=checkbox]:not(.toggle-checkbox), input[type=radio] {
    //Resets
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    display: inline-block;
    cursor: pointer;

    margin-right: $unit;
}

span.wpcf7-list-item {

    &.first {
        margin-left: 0;
    }

    .wpcf7-list-item-label {
        margin-right: $unit * 2;
    }
}

input[type=checkbox]:not(.toggle-checkbox) {
    appearance: checkbox;
    @include color(accent-color, 'accent-primary')
}

input[type=radio] {
    appearance: radio;
    @include color(accent-color, 'accent-primary')
}

.wpcf7-checkbox {
    text-align: left;
}

// @link https://stackoverflow.com/questions/67111265/how-to-make-a-toggle-button-with-vanilla-javascript-and-css-without-any-html
.toggle {
    position: relative;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;

    @include color(background-color, 'body-10');

    .toggle-checkbox {
        position: absolute;
        left: -9999px;
    }

    .toggle-label {
        position: relative;
        display: inline-block;
        top: -1px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 16px;
        @include color(background-color, 'text-light');
        transition: all .25s ease-in-out;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            @include color(color, 'text-light');
            font: {
                size: 13px;
                weight: bold;
            }
            text-transform: uppercase;
            line-height: 32px;
            transition: all .15s ease-in-out;
        }

        &:before {
            left: -30px;
            opacity: 0;
        }

        &:after {
            left: 37px;
            opacity: 1;
        }
    }

    .toggle-checkbox:checked ~ .toggle-label {
        left: 24px;

        &:before {
            opacity: 1;
        }

        &:after {
            opacity: 0;
        }
    }

    &.selected {
        @include color(background-color, 'accent-primary');
    }

    &.disabled {
        pointer-events: none;
        @include color(background-color, 'body-10');

        .toggle-label {
            @include color(background-color, 'body-25');
        }
    }
}
