//Define type styles that can be used as utility classes or mixins elsewhere.

@mixin heading-0 {
    text-transform: uppercase;
    font-family: $font-stack-headings;
    font-weight: 200;
    @include size-4XL;
    @include breakpoint(S) {
        @include size-4XL;
    }

    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;

    line-height: 0.85;
    @include color(color, 'text-light');
    &:not(.no-shadow) {
        text-shadow: 8px 8px 0 $color-body,
                     7px 7px 0 $color-body,
                     6px 6px 0 $color-body,
                     5px 5px 0 $color-body,
                     4px 4px 0 $color-body,
                     3px 3px 0 $color-body,
                     2px 2px 0 $color-body,
                     1px 1px 0 $color-body;
    }
}

@mixin heading-1 {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-headings;
    font-weight: 700;
    @include size-3XL;
    line-height: 1.1;
    text-transform: uppercase;

    @include breakpoint(XS) {
        font-weight: 400;
    }
}

@mixin heading-2 {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-headings;
    font-weight: 700;
    @include size-2XL;
    line-height: 1.2;
    text-transform: uppercase;

    @include breakpoint(XS) {
        font-weight: 400;
    }
}

@mixin heading-3 {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-headings;
    font-weight: 700;
    @include size-XL;
    line-height: 1.33;
    text-transform: uppercase;

    @include breakpoint(XS) {
        font-weight: 400;
    }
}

@mixin heading-4 {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-L;
    line-height: 1.33;
    text-transform: uppercase;
}

@mixin heading-5 {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-M;
    line-height: 1.33;
    text-transform: uppercase;
}

@mixin subtitle-1 {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-subheadings;
    font-weight: bold;
    @include size-XL;
    line-height: 1.5;
    text-shadow: none;
    text-transform: uppercase;
    letter-spacing: 0.3em;
}

@mixin subtitle-2 {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-subheadings;
    font-weight: bold;
    @include size-L;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 0.3em;
}

@mixin body-L {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-L;
    line-height: 1.5;
}

@mixin body-M {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-M;
    line-height: 1.5;
}

@mixin body-S {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-S;
    line-height: 1.5;
}

@mixin body-XS {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-XS;
    line-height: 1.5;
}

@mixin overline {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-primary;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    @include size-XS;
    line-height: 1.5;
}

@mixin button-text {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-M;
    line-height: 1.5;
}

@mixin label {
    // Ensure decorative icons aren't displayed above text
    z-index: 2;
    position: relative;
    font-family: $font-stack-headings;
    font-weight: 700;
    @include size-XS;
    line-height: 1.5;
}


//Define utility classes here (TODO: merge _headings.scss into this section)

.subtitle-1 {
    @include subtitle-1;
}

.subtitle-2 {
    @include subtitle-2;
}

.body-l {
    @include body-L;
}

.body-m {
    @include body-M;
}

.body-s {
    @include body-S;
}

.body-xs {
    @include body-XS;
}

.overline {
    @include overline;
}

.button-text {
    @include button-text;
}

.label {
    @include label;
}
