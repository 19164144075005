//Configure your project's core colour palette here
// ================================================

$color-body: #130839;
$color-background: #E9BED6;
$color-background-alt: #f3f3e8;
$color-background-disabled: #e8e7ec;

$color-accent-primary: #DB59A0;
$color-accent-punk-pink: #DB59A0;
$color-accent-beach-blue: #80C8FA;
$color-accent-balearic-blue: #29ADE4;
$color-accent-rave-green: #22D66B;
$color-accent-punk-yellow: #FFE800;
$color-accent-deep-purple: #8A5BAE;
$color-accent-seaside-beige: #F2CA8B;

$color-success: #4CC27B;
$color-warning: #EEBE43;
$color-error: #DE3C4B;

$color-text-light: #ffffff;

// Define your standard colour variables here
// ================================================

$color-vars: (
  body: $color-body,
  body-75: rgba($color-body, 0.75),
  body-50: rgba($color-body, 0.50),
  body-25: rgba($color-body, 0.25),
  body-10: rgba($color-body, 0.10),
  body-05: rgba($color-body, 0.05),
  body-01: rgba($color-body, 0.01),

  background: $color-background,
  background-75: rgba($color-background, 0.75),
  background-50: rgba($color-background, 0.5),
  background-25: rgba($color-background, 0.25),
  background-10: rgba($color-background, 0.10),
  background-05: rgba($color-background, 0.05),
  background-01: rgba($color-background, 0.01),

  background-alt: $color-background-alt,
  background-disabled: $color-background-disabled,

  accent-primary: $color-accent-primary,
  accent-primary-lightest: mix($color-accent-primary, #FFF, 25%),
  accent-primary-lighter: mix($color-accent-primary, #FFF, 50%),
  accent-primary-light: mix($color-accent-primary, #FFF, 87.5%),
  accent-primary-dark: mix($color-accent-primary, #000, 87.5%),
  accent-primary-darker: mix($color-accent-primary, #000, 50%),
  accent-primary-darkest: mix($color-accent-primary, #000, 25%),

  accent-punk-pink: $color-accent-punk-pink,
  accent-punk-pink-lightest: mix($color-accent-punk-pink, #FFF, 25%),
  accent-punk-pink-lighter: mix($color-accent-punk-pink, #FFF, 50%),
  accent-punk-pink-light: mix($color-accent-punk-pink, #FFF, 87.5%),
  accent-punk-pink-dark: mix($color-accent-punk-pink, #000, 87.5%),
  accent-punk-pink-darker: mix($color-accent-punk-pink, #000, 50%),
  accent-punk-pink-darkest: mix($color-accent-punk-pink, #000, 25%),

  accent-beach-blue: $color-accent-beach-blue,
  accent-beach-blue-lightest: mix($color-accent-beach-blue, #FFF, 25%),
  accent-beach-blue-lighter: mix($color-accent-beach-blue, #FFF, 50%),
  accent-beach-blue-light: mix($color-accent-beach-blue, #FFF, 87.5%),
  accent-beach-blue-dark: mix($color-accent-beach-blue, #000, 87.5%),
  accent-beach-blue-darker: mix($color-accent-beach-blue, #000, 50%),
  accent-beach-blue-darkest: mix($color-accent-beach-blue, #000, 25%),

  accent-balearic-blue: $color-accent-balearic-blue,
  accent-balearic-blue-lightest: mix($color-accent-balearic-blue, #FFF, 25%),
  accent-balearic-blue-lighter: mix($color-accent-balearic-blue, #FFF, 50%),
  accent-balearic-blue-light: mix($color-accent-balearic-blue, #FFF, 87.5%),
  accent-balearic-blue-dark: mix($color-accent-balearic-blue, #000, 87.5%),
  accent-balearic-blue-darker: mix($color-accent-balearic-blue, #000, 50%),
  accent-balearic-blue-darkest: mix($color-accent-balearic-blue, #000, 25%),

  accent-rave-green: $color-accent-rave-green,
  accent-rave-green-lightest: mix($color-accent-rave-green, #FFF, 25%),
  accent-rave-green-lighter: mix($color-accent-rave-green, #FFF, 50%),
  accent-rave-green-light: mix($color-accent-rave-green, #FFF, 87.5%),
  accent-rave-green-dark: mix($color-accent-rave-green, #000, 87.5%),
  accent-rave-green-darker: mix($color-accent-rave-green, #000, 50%),
  accent-rave-green-darkest: mix($color-accent-rave-green, #000, 25%),

  accent-punk-yellow: $color-accent-punk-yellow,
  accent-punk-yellow-lightest: mix($color-accent-punk-yellow, #FFF, 25%),
  accent-punk-yellow-lighter: mix($color-accent-punk-yellow, #FFF, 50%),
  accent-punk-yellow-light: mix($color-accent-punk-yellow, #FFF, 87.5%),
  accent-punk-yellow-dark: mix($color-accent-punk-yellow, #000, 87.5%),
  accent-punk-yellow-darker: mix($color-accent-punk-yellow, #000, 50%),
  accent-punk-yellow-darkest: mix($color-accent-punk-yellow, #000, 25%),

  accent-deep-purple: $color-accent-deep-purple,
  accent-deep-purple-lightest: mix($color-accent-deep-purple, #FFF, 25%),
  accent-deep-purple-lighter: mix($color-accent-deep-purple, #FFF, 50%),
  accent-deep-purple-light: mix($color-accent-deep-purple, #FFF, 87.5%),
  accent-deep-purple-dark: mix($color-accent-deep-purple, #000, 87.5%),
  accent-deep-purple-darker: mix($color-accent-deep-purple, #000, 50%),
  accent-deep-purple-darkest: mix($color-accent-deep-purple, #000, 25%),

  accent-seaside-beige: $color-accent-seaside-beige,
  accent-seaside-beige-lightest: mix($color-accent-seaside-beige, #FFF, 25%),
  accent-seaside-beige-lighter: mix($color-accent-seaside-beige, #FFF, 50%),
  accent-seaside-beige-light: mix($color-accent-seaside-beige, #FFF, 87.5%),
  accent-seaside-beige-dark: mix($color-accent-seaside-beige, #000, 87.5%),
  accent-seaside-beige-darker: mix($color-accent-seaside-beige, #000, 50%),
  accent-seaside-beige-darkest: mix($color-accent-seaside-beige, #000, 25%),

  accent-reverse: $color-background,
  accent-reverse-alt: $color-body,

  success: $color-success,
  success-lightest: mix($color-success, #FFF, 25%),
  success-lighter: mix($color-success, #FFF, 50%),
  success-light: mix($color-success, #FFF, 87.5%),
  success-dark: mix($color-success, #000, 87.5%),
  success-darker: mix($color-success, #000, 50%),
  success-darkest: mix($color-success, #000, 25%),

  warning: $color-warning,
  warning-lightest: mix($color-warning, #FFF, 25%),
  warning-lighter: mix($color-warning, #FFF, 50%),
  warning-light: mix($color-warning, #FFF, 87.5%),
  warning-dark: mix($color-warning, #000, 87.5%),
  warning-darker: mix($color-warning, #000, 50%),
  warning-darkest: mix($color-warning, #000, 25%),

  error: $color-error,
  error-lightest: mix($color-error, #FFF, 25%),
  error-lighter: mix($color-error, #FFF, 50%),
  error-light: mix($color-error, #FFF, 87.5%),
  error-dark: mix($color-error, #000, 87.5%),
  error-darker: mix($color-error, #000, 50%),
  error-darkest: mix($color-error, #000, 25%),

  text-light: $color-text-light,
  text-light-lightest: mix($color-text-light, #FFF, 25%),
  text-light-lighter: mix($color-text-light, #FFF, 50%),
  text-light-light: mix($color-text-light, #FFF, 87.5%),
  text-light-dark: mix($color-text-light, #000, 87.5%),
  text-light-darker: mix($color-text-light, #000, 50%),
  text-light-darkest: mix($color-error, #000, 25%),


);

// A mixin that gives you an appropriate fallback for browsers that don't support CSS variables (basically just IE)
// Usage example:
//  @include color(background-color, 'accent-primary', #333);
// outputs:
//  background-color: #333;
//  background-color: var(--accent-primary, #333);
//
// Tip: You don't need to include a fallback variable for most cases – you only need to use it when your desired
//      IE fallback isn't the standard default value (e.g. setting text colour over an image background)

@mixin color($property, $color, $fallback: null) {
    @if $fallback {
        #{$property}: #{$fallback} !important;
        #{$property}: var(--color-#{$color}, #{$fallback}) !important;
    } @else {
        #{$property}: map-deep-get($color-vars, $color) !important;
        #{$property}: var(--color-#{$color}, map-get($color-vars, $color)) !important;
    }
}
