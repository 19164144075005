:root {
    --tec-color-accent-primary: #DB59A0;
    --tec-color-background: #E9BED6;
    --tec-color-background-events: #fff;
    --tec-font-family-sans-serif: "Effra-Std", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    --tec-color-background-events-bar: #F3F3E8;
}

.tribe-events-view {
    @include color(background-color, 'background');
}

// Not in use anymore, but quite a handy function so I'm going to leave commented in in case I need to readd it again

            // @mixin random-bgr() {
            //     background-color: nth($list, random(length($list))) !important;
            // }

            // $list: #DB59A0, #80C8FA, #29ADE4, #22D66B, #FFE800, #8A5BAE, #F2CA8B;

            // @for $i from 1 through 12 {
            //     .button.month-selector:nth-child(#{length($list)}n+#{$i}) {
            //         @include random-bgr();
            //     }
            // }

.button.month-selector {
    @include color(background-color, 'accent-beach-blue');

    &.active-month {
        @include color(background-color, 'accent-primary');
    }
}

.tribe-events .tribe-events-c-top-bar__datepicker-container {
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.tribe-events .tribe-events-calendar-month__events { 
    @include color(background-color, 'accent-reverse');
}

.tribe-events .tribe-events-calendar-month__day-cell--mobile {
    @include color(background-color, 'accent-reverse');
}

.tribe-common button.tribe-events-c-top-bar__datepicker-button {
    padding: 4px 12px 8px;
    border-radius: 12px;
}
.tribe-common--breakpoint-medium.tribe-events {

    .tribe-events-calendar-month__day {
        border: 1px solid;
        @include color(border-color, 'body');
        min-height: 130px;

        &-cell {
            position: relative;
        }
    }
    .tribe-events-calendar-month__day-date {
        opacity: 1;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0);

        .tribe-events-calendar-month__day-date-daynum {
            display: block;
            width: auto;
            border-radius: 12px;
            background-color: #fff;;
            padding: 6px;
            margin-top: 5px;
            margin-left: 5px;
        }
    }

    .tribe-events-calendar-month__calendar-event {
        display: block;
        background-size: cover;
        background-position: center center;
        height: 100%;
        width: 100%;
        position: relative;
        opacity: 1;

        h3:not(.tribe-events-calendar-month__day-date), a {
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include color(color, 'text-light');
            text-shadow: 8px 8px 0 $color-body,
                        7px 7px 0 $color-body,
                        6px 6px 0 $color-body,
                        5px 5px 0 $color-body,
                        4px 4px 0 $color-body,
                        3px 3px 0 $color-body,
                        2px 2px 0 $color-body,
                        1px 1px 0 $color-body;
            @include size-XL;
            font-weight: 700;
            font-family: $font-stack-headings;
            text-transform: uppercase;
        }
    }
}

.tribe-events .tribe-events-calendar-month__day {
    border: 1px solid #130839;
    border-radius: 0;
}

.tribe-events .tribe-events-calendar-month__day-date {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.tribe-events .tribe-events-c-top-bar__datepicker-container .dropdown-menu {
    background-color: #fff;
}

.tribe-events .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-date, .tribe-events .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-date-link {
    time {
        color: #DB59A0;
    }
}

.tribe-events .tribe-events-calendar-month__day-cell--selected .tribe-events-calendar-month__mobile-events-icon--event {
    @include color(background-color, 'accent-balearic-blue');
}

.tribe-events .tribe-events-calendar-month-mobile-events__mobile-event {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: start;
    padding-top: 2rem;
}

.tribe-events .tribe-events-calendar-month__day-date-daynum {
    display: block;
    width: auto;
    border-radius: 12px;
    background-color: #fff;
    padding: 3px;
    margin-top: 0;
    margin-left: -5px;
}

.tribe-events-calendar-month-mobile-events__mobile-event-datetime time{
    font-family: $font-stack-headings;
    font-size: 1.2rem;
    font-style: normal;
    @include color(color, 'body');
    text-transform: uppercase;
}

.tribe-events-calendar-month-mobile-events__mobile-event-title-link, .tribe-events-calendar-month-mobile-events__mobile-event-title {
    font-size: 2rem;
    font-family: $font-stack-headings;
    @include color(color, 'accent-punk-pink');
    font-weight: 400;
}

.tribe-events .tribe-events-c-nav {
    border: none;
}

.tribe-common--breakpoint-medium .tribe-filter-bar--horizontal.tribe-filter-bar .tribe-filter-bar__form {
    justify-content: flex-end;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-header--has-event-search .tribe-events-c-events-bar {
    box-shadow: 6px 6px 0 #130839,5px 5px 0 #130839,4px 4px 0 #130839,3px 3px 0 #130839,2px 2px 0 #130839,1px 1px 0 #130839;
    border-radius: 12px;
    border: 1px solid;
    @include color(border-color, 'body');

    input {
        box-shadow: none;
        font-size: 1rem;
        border-radius: 12px !important;
    }
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-c-events-bar__search-container {
    border-radius: 12px;
}

.tribe-common--breakpoint-medium.tribe-events--filter-bar-horizontal.tribe-events .tribe-events-header--has-event-search .tribe-events-c-events-bar__filter-button {
    background-color: transparent !important;
    box-shadow: none;
    font-size: 1rem;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-common-c-btn.tribe-events-c-search__button {
    box-shadow: none;
    font-size: 2rem;
    font-weight: 400;
    text-shadow: -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;
    font-family: $font-stack-headings;
    padding: 4px 12px 8px 12px;
    line-height: 133%;
    border-radius: 12px;
    border: 1px solid;
    @include color(border-color, 'body');
}

.tribe-common--breakpoint-medium.tribe-events--filter-bar-horizontal.tribe-events .tribe-events-header--has-event-search .tribe-events-c-events-bar__filter-button-text {
    font-size: 1rem;
}

.tribe-filter-bar .tribe-filter-bar-c-pill--button .tribe-filter-bar-c-pill__pill {
    box-shadow: none !important;
    background-color: #fff !important;
    padding: 0.5rem 1rem !important;
    font-family: $font-stack-headings;
    font-size: .875rem !important;
    line-height: 150%;
    @include color(color, 'body');
    min-width: unset;
    min-height: unset;

    &:hover {
        background-color:#80c8fa !important;
    }
}

.tribe-filter-bar-c-filter__filters-close {
    box-shadow: none !important;
    background-color: var(--tec-color-background-events-bar) !important;
    font-family: $font-stack-headings;
    font-size: .875rem !important;
    line-height: 150%;
    @include color(color, 'body');
    min-width: unset;
    min-height: unset;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-l-container {
    padding-top: 2rem;
}

.tribe-events ul li::before {
    display: none;
}

.tribe-events-c-top-bar__datepicker-button {
    box-shadow: none !important;
    background-color: transparent !important;
    font-family: $font-stack-headings !important;
    @include color(color, 'body');
    font-size: 1.75rem;

    &:hover {
        background-color: transparent !important;
    }
}

.tribe-events-c-top-bar__today-button, .tribe-events-c-top-bar__datepicker-button {
    border-radius: 0.75rem !important;
    background-color: var(--tec-color-background-events-bar) !important;
    padding: 4px 12px 8px 12px !important;
    font-size: 1.75rem !important;
    line-height: 1.33 !important;
    font-weight: 400 !important;
    font-family: $font-stack-headings !important;
    text-transform: uppercase;
    @include color(color, 'body');
    border: 1px solid !important;
    @include color(border-color, 'body');
}

.tribe-events-c-top-bar__datepicker-button {
    &:hover {
        background-color: var(--tec-color-background-events-bar) !important;
        color: var(--tec-color-background) !important;
    }
}

.tribe-common .tribe-common-c-btn-icon--caret-left .tribe-common-c-btn-icon__icon-svg path, .tribe-common .tribe-common-c-btn-icon--caret-right .tribe-common-c-btn-icon__icon-svg path {
    fill: var(--color-body);
}

.tribe-events .tribe-events-c-top-bar__nav-list-item {
    padding-right: 2rem;
}

.tribe-events-c-events-bar__search-button,
.tribe-events-c-events-bar__filter-button,
.tribe-events-c-view-selector__button {
    min-width: unset;
    box-shadow: none !important;
    background-color: transparent !important;
}

.tribe-events .tribe-events-c-events-bar .tribe-common-form-control-text__input {
    box-shadow: none;
}

.tribe-filter-bar .tribe-filter-bar-c-filter {
    button {
        box-shadow: none !important;
        background-color: var(--tec-color-background-events-bar) !important;
        color: var(--color-body) !important;
        padding-left: 0.75rem !important; 
        padding-right: 0.75rem !important; 
    }
}

.tribe-events button.tribe-events-c-top-bar__nav-link--next:disabled, .tribe-events button.tribe-events-c-top-bar__nav-link--prev:disabled {
    border-radius: 100% !important;
}