.grecaptcha-badge {
    display: none !important;
}

.error.notice {
    display: none;
    position: fixed;
    width: 17em;
    right: 5em;
    bottom: 2em;
    z-index: 20;
    @include color(color, 'background');
    font-size: 16px;
    border-radius: 8px;
    @include color(background-color, 'background');
    border: 1px solid;
    @include color(border-color, 'body');

    :before {
        content: "Caching is disabled";
        display: block;
        font-weight: 700;
        @include color(color, 'body');
    }
    p {
        margin-bottom: 0em;
        padding: 2em;
    }
}

.js-category-target {
    @extend %transition;

    &.filtered {
        display: none;
    }
    &.fade-out {
        opacity: 0;
    }
}

.footer {
    clear: both; // just in case any floating elements
}
