.footer {
    position: relative;
    border-top: 1px solid;
    border-bottom: 1px solid;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include breakpoint(M) {
            justify-content: flex-start;
        }

        a {
            font-weight: 400;
            @include color(color, 'body');
            display: block;

            &:hover {
                @include color(color, 'accent-primary');
            }
        }
    }

    .left-footer-box, .right-footer-box {
        position: absolute;
        top: 0;
        width: 2rem;
        height: 100%;
    }

    .left-footer-box {
        left: 0;
        border-right: 1px solid;
        @include color(background, 'background');
    }

    .right-footer-box {
        right: 0;
        border-left: 1px solid;
        @include color(background, 'background');
    }

    @include breakpoint(M) {
        .left-footer-box,
        .right-footer-box {
            display: none;
        }
    }

    .footer-content-container {
        flex-wrap: wrap;

        > div {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        > div.footer-menu-container {
            flex: 0 0 50%;

            @include breakpoint(M) {
                flex: 0 0 100%;
            }
        }

        > div.social-links-container {
            flex: 0 0 25%;
            @include color(background, 'accent-punk-yellow');
            border-left: 1px solid;
            border-right: 1px solid;

            @include breakpoint(M) {
                flex: 0 0 100%;
                border-top: 1px solid;
                border-bottom: 1px solid;
                @include color(border-color, 'body');
            }

            h3, h4 {
                width: 100%;
                text-align: center;
                padding: 0 4rem 0 4rem;

                @include breakpoint(M) {
                    padding: 0 3rem 0 3rem;
                }
            }

            h3 {
                @include color(color, 'accent-deep-purple');
            }

            .social-links {
                display: flex;
                width: 15em;
                flex-wrap: wrap;

                @include breakpoint(M) {
                    width: 100%;
                    justify-content: space-around;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }

                a {
                    flex: 0 0 50%;

                    @include breakpoint(M) {
                        flex: 0;
                    }

                    svg {
                        height: 37.5px;
                        width: 37.5px;
                        @include color(fill, 'body');
                    }
                }
            }
        }

        > div.partner-links-container {
            flex: 0 0 25%;
            @include color(background, 'accent-rave-green-light');

            @include breakpoint(M) {
                flex: 0 0 100%;
            }

            h3, h4 {
                width: 100%;
                text-align: center;
                padding: 0 4rem 0 4rem;

                @include breakpoint(M) {
                    padding: 0 3rem 0 3rem;
                }
            }

            h3 {
                @include color(color, 'accent-deep-purple');
            }

            .partner-links {
                display: grid;
                grid-column-gap: 1rem;
                grid-template-columns: repeat(2, 1fr);
                width: 15em;

                @include breakpoint(M) {
                    width: 100%;
                    grid-template-columns: repeat(4, 1fr);
                    padding-left: 2rem;
                    padding-right: 2rem;
                }

                a {
                    flex: 0 0 50%;

                    @include breakpoint(M) {
                        flex: 0 0 calc(100% / 6);
                    }

                    svg {
                        width: 10rem;
                        @include color(fill, 'body');
                    }

                    img {
                        width: 10rem;
                    }
                }
            }
        }

        ul#menu-footer-menu {
            padding-left: 5rem;
            padding-right: 2rem;

            @include breakpoint(M) {
                padding-left: 3rem;
                padding-right: 3rem;
            }

            ul.sub-menu {
                display: flex;
                flex-direction: column;
            }

            li.menu-item-has-children {
                width: 33%;

                @include breakpoint(S) {
                    flex: 0 0 50%;
                    width: unset;
                }

                &> a {
                    pointer-events: none;
                    @include heading-4;

                    margin-bottom: -0.6rem;
                }

                @include breakpoint(S) {
                    &:last-of-type {
                        margin-top: 2rem;
                    }
                }
            }

            li {
                border-bottom: none;
            }

            li:not(.menu-item-has-children) a {
                margin-top: 1rem;
            }
        }

        .copyright-container {
            flex: 0 0 100%;
            padding: 1.5rem 4rem 1.5rem 4rem;

            @include breakpoint(S) {
                padding: 2rem 0.5rem;
            }

            border-top: 1px solid;

            p, a {
                @include color(color, 'body');
                font-size: 1rem;
                margin: 0;
            }
        }
    }
}

