/*
Font: 		Vanguard CF
Style: 		Extra Bold Oblique
URL: 		https://www.youworkforthem.com/font/T7923/vanguard-cf
Foundry: 	Connary Fagen
Foundry: 	https://www.youworkforthem.com/designer/479/connary-fagen
Copyright:	Copyright © 2017 by Connary Fagen. All rights reserved.
Version: 	17
Created:	August 12, 2019
License: 	https://www.youworkforthem.com/font-license
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted
			from using the WebFonts(s) listed without a purchased license.
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'VanguardCF-ExtraBoldOblique';
  src: url('/wp-content/themes/ibiza-rocks/assets/fonts/vanguard-cf-extra-bold-oblique.woff2') format('woff2'),
  url('/wp-content/themes/ibiza-rocks/assets/fonts/vanguard-cf-extra-bold-oblique.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Effra-Std';
  src: url('/wp-content/themes/ibiza-rocks/assets/fonts/effra_std_md_2-webfont.woff2') format('woff2'),
  url('/wp-content/themes/ibiza-rocks/assets/fonts/effra_std_md_2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vanguard CF';
  src: url('/wp-content/themes/ibiza-rocks/assets/fonts/vanguard-cf-light-oblique.woff2') format('woff2'),
  url('/wp-content/themes/ibiza-rocks/assets/fonts/vanguard-cf-light-oblique.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Fonts for UP Core Vogue
$font-stack-primary: 'Effra-Std', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-stack-headings: 'VanguardCF-ExtraBoldOblique', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-stack-subheadings: 'Vanguard CF', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$header-height: 5rem;
$rounded-border-radius: 24px;
$image-border-radius: 8px;

// General Breakpoints
$breakpoints: (
    'XXS': ( max-width:  30em ),
    'XS': ( max-width:  40em ),
    'XS-up': ( min-width:  641px ),
    'S': ( max-width:  48em ),
    'S-up': ( min-width: 769px ),
    'M': ( max-width:  64em ),
    'M-up': ( min-width:  64em ),
    'ML': ( max-width:  75em ),
    'ML-up': ( min-width:  75em ),
    'L': ( max-width: 91em ),
    'XL': ( min-width: 91em ),
    'XXL': ( min-width: 105em ),
    'XXXL': ( min-width: 112em )
);
